<template>
  <div class="DarkWhite">
      <v-row row class="mt-3">
      <v-col cols="12" lg="6" class="d-flex">
          <span>
            Purchase Request Information
          </span>
          <v-divider class="mx-1 mt-3"></v-divider>
      </v-col>
      <v-col cols="12" md="6" class="hidden-md-and-down">
        <span class="d-flex">
          <span>
            Purchase Order Information
          </span>
          <v-divider class="mx-1 mt-3"></v-divider>
        </span>
      </v-col>
      </v-row>
      <v-row>
      <v-col cols="12" sm="6" lg="3" v-for="(item, index) in pr_items1" :key="item.text">
      <v-card elevation="0" outlined>
        <v-list-item three-line>
          <DoughnutChart class="doughnut-style" :content="item" :ref="'DoughnutChartPPMP' + index+1" />
          <v-list-item-content class="ml-5">
            <v-list-item-subtitle color="#808191" class="text-subtitle-1">{{item.text}}</v-list-item-subtitle>
            <v-list-item-title class="text-h4 mb-1">
              {{parseInt(item.counter)}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
      <v-col cols="12" class="ml-1 hidden-lg-and-up">
        <span class="d-flex">
          <span>
            Purchase Order Information
          </span>
          <v-divider class="mx-1 mt-3"></v-divider>
          </span>
      </v-col>
      <v-col cols="12" sm="6" lg="3" v-for="(item, index) in po_items1" :key="item.text">
      <v-card elevation="0" outlined>
        <v-list-item three-line>
          <DoughnutChart class="doughnut-style" :content="item" :ref="'DoughnutChartPPMP' + index+1" />
          <v-list-item-content class="ml-5">
            <v-list-item-subtitle color="#808191" class="text-subtitle-1">{{item.text}}</v-list-item-subtitle>
            <v-list-item-title class="text-h4 mb-1">
              {{parseInt(item.counter)}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    </v-row>
    <v-row row>
      <v-col cols="12" lg="6">
        <v-card class="card-style" elevation="0" outlined>
            <v-list-item three-line>
              <v-list-item-content class="ml-5">
                <v-list-item-title class="text-h5 my-2">
                  Monthly Purchase Request <small> - <b>{{mpr_data.total || 0}}</b> Total PRs</small>
                </v-list-item-title>
                <v-list-item-subtitle color="#808191" class="text-subtitle-1">
                  <BarChart :content="mpr_data" />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card class="card-style" elevation="0" outlined>
            <v-list-item three-line>
              <v-list-item-content class="ml-5">
                <v-list-item-title class="text-h5 my-2">
                  Monthly Purchase Order <small> - <b>{{mpo_data.total || 0}}</b> Total POs</small>
                </v-list-item-title>
                <v-list-item-subtitle color="#808191" class="text-subtitle-1">
                  <BarChart :content="mpo_data" />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" lg="12">
        <v-card class="card-style" elevation="0" outlined>
            <v-list-item three-line>
              <v-list-item-content class="ml-5">
                <v-list-item-title class="text-h5 my-2">
                  Monthly Inspection And Acceptance <small> - <b>{{mia_data.total || 0}}</b> Total IARs</small>
                </v-list-item-title>
                <v-list-item-subtitle color="#808191" class="text-subtitle-1">
                  <BarChart :content="mia_data" />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    </div>
</template>

<script>
import BarChart from "../Charts/BarChart.vue";
import DoughnutChart from "../Charts/DoughnutChart.vue";
export default {
  components: {
      BarChart,
      DoughnutChart,
    },
  props: {
    fiscalYear: null
  },
  data: () => ({
    pr_items1: [
      {
        colors: "green darken-1",
        icon: "mdi-clipboard-check",
        text: "Approved PR",
        counter: 0,
        label: ["Approved", "Total"],
        chartType: 1
      },
      {
        colors: "orange darken-1",
        icon: "mdi-clipboard-clock",
        text: "Pending PR",
        counter: 0,
        label: ["Approved", "Total"],
        chartType: 2
      },
    ],
    po_items1: [
      {
        colors: "green darken-1",
        icon: "mdi-clipboard-check",
        text: "Approved PO",
        counter: 0,
        label: ["Approved", "Total"],
        chartType: 1
      },
      {
        colors: "orange darken-1",
        icon: "mdi-clipboard-clock",
        text: "Pending PO",
        counter: 0,
        label: ["Approved", "Total"],
        chartType: 2
      },
    ],
    labels_pr: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    value_pr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    labels_po: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    value_po: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    prChartData_count: 0,
    poChartData_count: 0,
    mpr_data: [],
    mpo_data: [],
    mia_data: [],
  }),
watch: {
    fiscalYear: {
      handler(data) {
        this.$store.dispatch("setFiscalYear", data);
        this.initialize();
      },
      deep: true,
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loading = true;
      //this.getBudgetMonitoring();
      if(this.fiscalYear != null){
        this.getPendingPRCount();
        this.getApprovedPRCount();
        this.getPendingPOCount();
        this.getApprovedPOCount();
        this.prChartData();
        this.poChartData();
        this.iarChartData();
      }
    },
    // getBudgetMonitoring() {
    //   let data = new FormData();
    //   if (this.options.page) {
    //     data.append("page", this.options.page);
    //     data.append("itemsPerPage", this.options.itemsPerPage);
    //   } else {
    //     data.append("page", 1);
    //     data.append("itemsPerPage", 10);
    //   }
    //   data.append("searchText", this.searchText);
    //   this.axiosCall("/dashboard/budgetMonitoring", "POST", data).then(
    //     (res) => {
    //       this.loading = false;
    //       this.budgetMonitoringData = res.data.data.result;
    //       this.totalCount = res.data.data.total;
    //       if (this.totalCount <= 10) {
    //         this.options.page = 1;
    //       }
    //     }
    //   );
    // },
    getPendingPRCount() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/pendingPR", "POST", data).then((res) => {
        this.loading = false;
        this.pr_items1[1].counter = res.data.data.result[0].PendingPR || 0.01;
      });
    },
    getApprovedPRCount() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/approvedPR", "POST", data).then((res) => {
        this.loading = false;
        this.pr_items1[0].counter = res.data.data.result[0].ApprovedPR || 0.01;
      });
    },
    getPendingPOCount() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/pendingPO", "POST", data).then((res) => {
        this.loading = false;
        this.po_items1[1].counter = res.data.data.result[0].PendingPO || 0.01;
      });
    },
    getApprovedPOCount() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/approvedPO", "POST", data).then((res) => {
        this.loading = false;
        this.po_items1[0].counter = res.data.data.result[0].ApprovedPO || 0.01;
      });
    },
    prChartData(){
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/prChartData", "POST", data).then(
        (res) => {
          this.loading = false;
          let labels = [];
          let data = [];
          let total = 0;
          res.data.data.result.forEach(item => {
            labels.push(item.monthname + " " + item.year);
          });
          res.data.data.result.forEach(item => {
            data.push(item.totalcount);
            total = total + parseInt(item.totalcount);
          });
          this.mpr_data = {
            labels: labels,
            data: data,
            total: total,
            label: 'No. of Purchase Request',
          }
        }
      );
    },
    poChartData(){
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/poChartData", "POST", data).then(
        (res) => {
          this.loading = false;
          let labels = [];
          let data = [];
          let total = 0;
          res.data.data.result.forEach(item => {
            labels.push(item.monthname + " " + item.year);
          });
          res.data.data.result.forEach(item => {
            data.push(item.totalcount);
            total = total + parseInt(item.totalcount);
          });
          this.mpo_data = {
            labels: labels,
            data: data,
            total: total,
            label: 'No. of Purchase Order',
            bgColor: '#ffb300',
          }
        }
      );
    },
    iarChartData(){
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/iarChartData", "POST", data).then(
        (res) => {
          this.loading = false;
          let labels = [];
          let data = [];
          let total = 0;
          res.data.data.result.forEach(item => {
            labels.push(item.monthname + " " + item.year);
          });
          res.data.data.result.forEach(item => {
            data.push(item.totalcount);
            total = total + parseInt(item.totalcount);
          });
          this.mia_data = {
            labels: labels,
            data: data,
            total: total,
            label: 'No. of Inspection And Acceptance Receipt',
            bgColor: '#686868',
          }
        }
      );
    },
    getFiscalYears() {
      this.fiscalyears = [{id: 0, fiscalYear: "All"}]
      this.axiosCall("/getAllFiscalYear", "GET").then((res) => {
        this.fiscalyears = [{id: 0, fiscalYear: "All"}, ...res.data.data]
        this.fiscalYear = this.$store.state.fiscalYear != null ? this.$store.state.fiscalYear : res.data.data[0].id
      });
    },
  },
};
</script>

<style scoped>
.Offset {
  top: -20px !important;
  position: relative !important;
}
.v-sheet--offset {
  top: -15px;
  position: relative;
}
</style>
