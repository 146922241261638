<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  props: ['content'],
  data() {
    return {
      data: null,
    };
  },
  watch: {
    content: {
        handler () {
            this.renderGraph();
        },
        deep: true,
      },
    },
  methods: {
    renderGraph(){
        let bgColor = ['#239FAB', '#E4E8EF'];
        let label = this.content.label;
        let type = this.content.chartType;
        let subtotal = this.content.counter;
        let total = this.content.total;
        if(type == 1){
            bgColor = ['#43a047', '#E4E8EF'];
        }
        else if(type == 2){
            bgColor = ['#ffb300', '#E4E8EF'];
        }
        else if(type == 3){
            bgColor = ['#1f7ad4', '#E4E8EF'];
        }
        else if(type == 4){
            bgColor = ['#2ED480', '#E4E8EF'];
        }
    this.renderChart(
      {
        labels: label,
        datasets: [
          {
            backgroundColor: bgColor,
            data: [parseInt(subtotal),total]
          }
        ]
      },
      { responsive: true, maintainAspectRatio: false, legend: { display: false }  }
    );
   }
  }
};
</script>